import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static values = {
    allowDeselect: Boolean,
    open: Boolean,
  };

  connect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      settings: { allowDeselect: this.allowDeselectValue },
    });

    if (this.openValue) {
      this.slimSelect.open();
    }
  }
}
