<script setup>
import { inject, ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store";
import TopNav from "./TopNav.vue";
import _ from "lodash";

const db = inject("db");

const store = useServiceConsoleStore();
const { appointments } = storeToRefs(store);
const customers = ref({});

const customerIds = _.uniq(_.map(appointments.value, "customerId"));
Promise.all(
  customerIds.map((customerId) => db.getCustomer(customerId)),
).then((result) => (customers.value = _.keyBy(result, "id")));
</script>

<template>
  <div id="service-console--home" class="grow flex flex-col space-y-4">
    <TopNav title="Service Console" />

    <div class="px-2">
      <span class="h1">Welcome</span>
    </div>

    <div class="px-2">
      <span class="font-semibold">Appointments</span>
      <ul role="list" class="flex flex-col space-y-2">
        <li v-for="appointment of appointments" class="shadow hover:bg-selected">
          <router-link :to="`/appointments/${appointment.id}`" class="w-full flex justify-between px-2 py-2.5 border border-muted bg-raised shadow">
            <div class="w-full flex items-center space-x-2">
              <div class="w-full flex flex-col space-y-1">
                <span class="h2">{{ appointment.time }}</span>
                <span>{{ appointment.location?.id }}</span>
                <span>Customer: {{ customers[appointment.customerId]?.name }}</span>
                <div class="pt-1 flex justify-between">
                  <span>{{ appointment.ticketIds.length }} tickets</span>

                  <div>
                    <span v-for="serviceCategory of appointment.serviceCategories" :class="`${serviceCategory.color}-category-badge`">
                      {{ serviceCategory.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
