import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target", "template"];

  add(event) {
    if (event.currentTarget.dataset.nestedFormAdd) {
      const templateTarget = this.templateTargets.find((el) => {
        return (
          el.dataset.nestedFormTemplate ==
          event.currentTarget.dataset.nestedFormAdd
        );
      });

      const content = templateTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime().toString(),
      );

      this.targetTarget.insertAdjacentHTML("beforeend", content);
    } else {
      const content = this.templateTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime().toString(),
      );
      this.targetTarget.insertAdjacentHTML("beforeend", content);
    }

    this.element.dispatchEvent(
      new CustomEvent("nested-form#add", { bubbles: true }),
    );
  }

  remove(event) {
    event.stopPropagation();

    const wrapper = event.target.closest(".nested-form-wrapper");

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.classList.add("hidden");

      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "1";
    }

    this.element.dispatchEvent(
      new CustomEvent("nested-form#remove", { bubbles: true }),
    );
  }
}
