import _ from "lodash";
import { DirectUpload } from "@rails/activestorage";
import Backend from "./Backend";
import { tryAcquire, Mutex, E_ALREADY_LOCKED } from "async-mutex";
import pMap from "p-map";

class SyncManager {
  private db: any;
  private store: any;
  private backend: Backend;
  private watching: Boolean;
  private mutex: Mutex;

  constructor(organizationId, db, store) {
    this.db = db;
    this.store = store;
    this.backend = new Backend(organizationId);
    this.mutex = new Mutex();

    this.trigger = this.trigger.bind(this);

    this.nextSync = null;

    // setInterval(this.trigger, 1000);
  }

  public trigger() {
    // console.log("[SyncManager] trigger...")

    navigator.locks.request("my_resource", { ifAvailable: true }, async (lock) => {
      // console.log({lock})

      if (this.nextSync) { clearTimeout(this.nextSync) }
      
      if (!lock) {
        // console.log("Failed to acquire lock, will try again...");
        this.nextSync = setTimeout(this.trigger, 3000)
      } else {
        try {
          const done = await this.doSync()
          if (!done) {
            // console.log("Not done; Scheduling next sync");
            this.nextSync = setTimeout(this.trigger, 2000)
          }
        } catch (error) {
          console.error(error);
          this.nextSync = setTimeout(this.trigger, 3000)
        }
      }
    });
  }

  private async doSync(): Promise<Boolean> {
    // console.log("doSync --> A")

    const pendingTicketOperations = await this.db.getPendingTicketOperations();
    // console.log(`${pendingTicketOperations.length} pending ticket operations...`, _.map(pendingTicketOperations, "ulid"))

    // console.log("doSync --> B")

    const photoTypes = ["set-vin-photo", "set-mileage-photo", "set-engine-hours-photo", "add-inspection-item-photo", "add-service-task-photo"];

    // console.log("doSync --> C")

    // For photo operations with data that hasn't been uploaded yet...
    // console.log("Uploading photos")
    try {
      for (const ticketOperation of pendingTicketOperations) {
        // console.log("doSync --> C / 1")
        if (_.includes(photoTypes, ticketOperation.type) && !ticketOperation.payload.signedId) {
          // console.log(`Uploading photo for ${ticketOperation.type} ticket operation`, ticketOperation);

          // console.log("doSync --> C / 1 / A")

          const file = await this.db.getFile(ticketOperation.payload.fileUlid)          
          // console.log("doSync --> C / 1 / B")
          // const signedId = await directUpload("photo.jpg", file.data)
          const signedId = await this.backend.uploadFile("photo.jpg", file.data)
          // console.log("doSync --> C / 1 / C")

          ticketOperation.payload.signedId = signedId
          // console.log("doSync --> C / 1 / D")
          // console.log(`Setting ${ticketOperation.type} signed ID: ${ticketOperation.payload.signedId}`);
          await this.db.putTicketOperation(ticketOperation);
          // console.log("doSync --> C / 1 / E")
        }
      }
    } catch (error) {
      // console.log("doSync --> C [ERROR]")
      console.error(error);
      return false;

    }

    // console.log("doSync --> D")

    const readyTicketOperations = _.filter(pendingTicketOperations, (ticketOperation) => {
      if (_.includes(photoTypes, ticketOperation.type) && !ticketOperation.payload.signedId) {
        // console.error("Missing signedID for photo", { ticketOperation })
        return false;
      }
      return true;
    });

    // console.log("doSync --> E")

    readyTicketOperations.forEach(ticketOperation => {
      if (_.includes(photoTypes, ticketOperation.type)) {
        ticketOperation.payload = _.omit(ticketOperation.payload, ["fileUlid", "thumbnailData"]);
      }

      ticketOperation.payload = deepSnakeCaseKeys(ticketOperation.payload);
    })

    // console.log("doSync --> F")

    // console.log(`${readyTicketOperations.length} ready ticket operations...`)

    const groupedTicketOperations = _.groupBy(readyTicketOperations, "ticketId");

    // console.log("doSync --> G")

    for (const ticketId in groupedTicketOperations) {
      try {
        // console.log("doSync --> G / 1")
        const ticketOperations = groupedTicketOperations[ticketId];

        // console.log("doSync --> G / 2")

        const { ticket, appliedOperationUlids, unappliedOperationUlids } = await this.backend.applyTicketOperations(ticketId, ticketOperations);

        // console.log("doSync --> G / 3")

        _.each(unappliedOperationUlids, ulid => console.error("Rejected ticket operation:", ulid));

        // console.log("doSync --> G / 4")

        await this.db.putTicket(ticket)

        // console.log("doSync --> G / 5")

        await this.db.deleteTicketOperations(appliedOperationUlids);
        this.store.clearTicketOperations(appliedOperationUlids);

        // console.log("doSync --> G / 6")

        await this.db.rejectTicketOperations(unappliedOperationUlids);
        this.store.rejectTicketOperations(unappliedOperationUlids);

        // console.log("doSync --> G / 7")

        if (appliedOperationUlids > 0) {
          // console.log(`[SyncManager] Applied ${appliedOperationUlids.length} ticket operation(s) for ticket ${ticketId}.`)
        }
        if (unappliedOperationUlids > 0) {
          console.log(`[SyncManager] Rejected ${unappliedOperationUlids.length} ticket operation(s) for ticket ${ticketId}.`)
        }

        // console.log("doSync --> G / 8")

        this.store.setTicket(ticket);

        // console.log("doSync --> G / 9")
      } catch (error) {
        console.error(error)
        // console.log("doSync --> G [ERROR]", error)
      }
    }

    // console.log("doSync --> H")

    const remainingTicketOperations = await this.db.getPendingTicketOperations();
    if (remainingTicketOperations.length > 0) {
      return false;
    }

    return true;
  }
}

// async function directUpload(filename, data) {
//   const formData = new FormData();
//   formData.append("file", data)
//   await window.fetch(url, method: "POST", headers: { "X-CSRF-Token": })

//   // const response = await fetch(data);
//   // const blob = response.blob();
//   // const uploader = new DirectUpload(
//   //   new File([blob], filename),
//   //   "/rails/active_storage/direct_uploads"
//   // );
//   // uploader.create((error, blob) => {
//   //   if (error) {
//   //     throw new Error("Direct upload error: `${error}`")
//   //   }
//   //   return blob.signed_id;
//   // });

//   // return new Promise((resolve, reject) => {
//   //   fetch(data)
//   //     .then((result) => result.blob())
//   //     .then((blob) => {
//   //       const uploader = new DirectUpload(
//   //         new File([blob], filename),
//   //         "/rails/active_storage/direct_uploads"
//   //       );
//   //       console.log("Direct upload:", filename)
//   //       uploader.create((error, blob) => {
//   //         if (error) {
//   //           reject(error);
//   //         } else {
//   //           resolve(blob.signed_id);
//   //         }
//   //       });
//   //     })
//   //     .catch((error) => reject(error));
//   // });
// }

function deepSnakeCaseKeys(obj) {
  return _.transform(obj, (acc, value, key, target) => {
    const snakeCaseKey = _.isArray(target) ? key : _.snakeCase(key);
    acc[snakeCaseKey] = _.isObject(value) ? deepSnakeCaseKeys(value) : value;
  });
}

export default SyncManager;
