import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  toggle() {
    this.itemTarget.classList.toggle("hidden");
  }

  hide() {
    this.itemTarget.classList.add("hidden");
  }

  show() {
    this.itemTarget.classList.remove("hidden");
  }

  handleOutsideClick(event) {
    if (this.element === event.target || this.element.contains(event.target)) {
      return;
    }

    this.hide();
  }
}
