<script setup>
import { inject, nextTick, ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.js";
import { isInspectionItemComplete, isServiceTaskComplete } from "./util.js";
import { router } from "./router.js";
import _ from "lodash";

import Inspection from "./Inspection.vue";
import PhotoViewer from "./PhotoViewer.vue";
import Service from "./Service.vue";
import TopNav from "./TopNav.vue";
import VehicleSelection from "./VehicleSelection.vue";

const props = defineProps(["appointmentId", "id"]);

const db = inject("db");
const syncManager = inject("syncManager");
const photoProcessor = inject("photoProcessor");

const store = useServiceConsoleStore();

const activityCount = inject("activityCount");

const { getAppointment, getCustomer, getTicket } = storeToRefs(store);

const appointment = getAppointment.value(parseInt(props.appointmentId));
if (!appointment) router.push("/");

const ticket = ref(getTicket.value(parseInt(props.id)));
if (!ticket) router.push("/");

// Refs to elements so they can be scrolled into view.
// TODO Combine all refs into one object.
const inspectionItemRefs = ref({});
const serviceTaskRefs = ref({});

const customer = getCustomer.value(appointment.customerId);
if (!customer) router.push("/");

const showingVehicleSelection = ref(_.isNil(ticket.value.vehicle?.id));
const showingProductSelection = ref(false);
const showingPhoto = ref(false);
const showingPhotoRemoval = ref(null);

const currentPhoto = ref(null);

const focus = ref("");

function selectVehicle(vehicle) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "assign-vehicle",
      payload: { vehicle: vehicle },
    };

    store.addTicketOperation(props.id, "assign-vehicle", ticketOperation);
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    showingVehicleSelection.value = false;
    setFocus("vin-photo");
    activityCount.value--;
    resolve();
  }).catch((error) => console.error(error));
}

async function processPhoto(data) {
  if (_.isNil(data)) {
    return;
  }

  const blob = readDataUrlAsBlob(data);

  // Save original file to DB.
  const fileUlid = await db.addFile({ data, description: "original" });

  // Generate thumbnail.
  const thumbnailData = await photoProcessor.resizeBlob({
    width: 32,
    height: 32,
    quality: 0.4,
    blob,
  });
  return { fileUlid, thumbnailData };
}

function setVinPhoto(event) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      processPhoto(reader.result)
        .then(({ fileUlid, thumbnailData }) => {
          // Add ticket operation.
          const ticketOperation = {
            type: "set-vin-photo",
            payload: { fileUlid, thumbnailData },
          };
          applyTicketOperation(ticket.value, ticketOperation);
          store.addTicketOperation(
            props.id,
            "set-vin-photo",
            ticketOperation,
          );

          syncManager.trigger();

          // Clear input.
          event.target.file = "";

          activityCount.value--;
          resolve();
        })
        .catch((error) => console.error(error));
    };

    reader.readAsDataURL(event.target.files[0]);
  }).catch((error) => console.error(error));
}

function setMileagePhoto(event) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      processPhoto(reader.result)
        .then(({ fileUlid, thumbnailData }) => {
          // Add ticket operation.
          const ticketOperation = {
            type: "set-mileage-photo",
            payload: { fileUlid, thumbnailData },
          };
          applyTicketOperation(ticket.value, ticketOperation);
          store.addTicketOperation(
            props.id,
            "set-mileage-photo",
            ticketOperation,
          );

          syncManager.trigger();

          // Clear input.
          event.target.file = "";

          activityCount.value--;
          resolve();
        })
        .catch((error) => console.error(error));
    };

    reader.readAsDataURL(event.target.files[0]);
  }).catch((error) => console.error(error));
}

function setEngineHoursPhoto(event) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      processPhoto(reader.result)
        .then(({ fileUlid, thumbnailData }) => {
          // Add ticket operation.
          const ticketOperation = {
            type: "set-engine-hours-photo",
            payload: { fileUlid, thumbnailData },
          };
          applyTicketOperation(ticket.value, ticketOperation);
          store.addTicketOperation(
            props.id,
            "set-engine-hours-photo",
            ticketOperation,
          );

          syncManager.trigger();

          // Clear input.
          event.target.file = "";

          activityCount.value--;
          resolve();
        })
        .catch((error) => console.error(error));
    };

    reader.readAsDataURL(event.target.files[0]);
  }).catch((error) => console.error(error));
}

function setInspectionItemStatus(inspection, inspectionItem, event) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-inspection-item-status",
      payload: {
        inspectionId: inspection.id,
        inspectionItemId: inspectionItem.id,
        status: event.target.value,
      },
    };
    store.addTicketOperation(
      props.id,
      `set-inspection-item-status--${inspectionItem.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function addInspectionItemPhoto(inspection, inspectionItem, event) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      processPhoto(reader.result)
        .then(({ fileUlid, thumbnailData }) => {
          // Add ticket operation.
          const ticketOperation = {
            type: "add-inspection-item-photo",
            payload: {
              inspectionId: inspection.id,
              inspectionItemId: inspectionItem.id,
              fileUlid,
              thumbnailData,
            },
          };
          store.addTicketOperation(
            props.id,
            `add-inspection-item-photo--${inspectionItem.id}`,
            ticketOperation,
          );
          applyTicketOperation(ticket.value, ticketOperation);

          syncManager.trigger();

          // Clear input.
          event.target.file = "";

          resolve();
        })
        .catch((error) => console.error(error));
    };

    reader.readAsDataURL(event.target.files[0]);
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function removeInspectionItemPhoto(inspection, inspectionItem, photo) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "remove-inspection-item-photo",
      payload: {
        inspectionId: inspection.id,
        inspectionItemId: inspectionItem.id,
        photoId: photo.id,
      },
    };
    store.addTicketOperation(
      props.id,
      `remove-inspection-item-photo--${inspectionItem.id}-${photo.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function setInspectionItemComments(inspection, inspectionItem, event) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-inspection-item-comments",
      payload: {
        inspectionId: inspection.id,
        inspectionItemId: inspectionItem.id,
        comments: event.target.value,
      },
    };
    store.addTicketOperation(
      props.id,
      `set-inspection-item-comments--${inspectionItem.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function showPhoto(photo, onRemove) {
  showingPhoto.value = true;
  showingPhotoRemoval.value = onRemove;
  // console.log("showPhoto", { photo });
  currentPhoto.value = photo;
}

function dismissPhoto() {
  showingPhoto.value = false;
  currentPhoto.value = null;
}

function addServiceTaskPhoto(service, serviceTask, event) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      processPhoto(reader.result)
        .then(({ fileUlid, thumbnailData }) => {
          // Add ticket operation.
          const ticketOperation = {
            type: "add-service-task-photo",
            payload: {
              serviceId: service.id,
              serviceTaskId: serviceTask.id,
              fileUlid,
              thumbnailData,
            },
          };
          store.addTicketOperation(
            props.id,
            `add-service-task-photo--${serviceTask.id}`,
            ticketOperation,
          );
          applyTicketOperation(ticket.value, ticketOperation);

          syncManager.trigger();

          // Clear input.
          event.target.file = "";

          resolve();
        })
        .catch((error) => console.error(error));
    };

    reader.readAsDataURL(event.target.files[0]);
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function removeServiceTaskPhoto(service, serviceTask, photo) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "remove-service-task-photo",
      payload: {
        serviceId: service.id,
        serviceTaskId: serviceTask.id,
        photoId: photo.id,
      },
    };
    store.addTicketOperation(
      props.id,
      `remove-service-task-photo--${serviceTask.id}-${photo.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function setServiceTaskCompletion(service, task, completed) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-task-completion",
      payload: {
        serviceId: service.id,
        serviceTaskId: task.id,
        completed,
      },
    };
    store.addTicketOperation(
      props.id,
      `set-service-task-completion--${task.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

async function imageData(photo) {
  const file = await db.getFile(photo.fileKey);
  return file.data;
}

function advanceFromInspectionItem(inspection, item) {
  const index = inspection.items.findIndex((i) => i.id == item.id);

  if (index < inspection.items.length - 1) {
    const nextItem = inspection.items[index + 1];
    setFocusOnInspectionItem(nextItem);
  }
}

function advanceFromServiceTask(service, task) {
  const index = service.tasks.findIndex((i) => i.id == task.id);

  if (index < service.tasks.length - 1) {
    const nextItem = service.tasks[index + 1];
    setFocusOnServiceTask(nextItem);
  }
}

function hasFocus(value) {
  return focus.value === value;
}

function hasFocusOnInspectionItem(item) {
  return hasFocus(`item-${item.id}`);
}

function hasFocusOnServiceTask(task) {
  return hasFocus(`task-${task.id}`);
}

function setFocus(value) {
  focus.value = value;
}

function setFocusOnInspectionItem(item) {
  setFocus(`item-${item.id}`);
  nextTick(() => {
    inspectionItemRefs.value[item.id].scrollIntoView({
      behavior: "smooth",
    });
  });
}

function setFocusOnServiceTask(task) {
  setFocus(`task-${task.id}`);
  nextTick(() => {
    serviceTaskRefs.value[task.id].scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  });
}

function setServiceProductConsumption(service, product, quantity) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-product-consumption",
      payload: {
        serviceId: service.id,
        productId: product.id,
        quantity,
      },
    };
    store.addTicketOperation(
      props.id,
      `set-service-product-consumption--${service.id}--${product.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function removeServiceProductConsumption(service, productConsumption) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-product-consumption",
      payload: {
        serviceId: service.id,
        productId: productConsumption.productId,
        quantity: 0,
      },
    };
    store.addTicketOperation(
      props.id,
      `set-service-product-consumption--${service.id}--${productConsumption.productId}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function servicesAreAvailable(ticket) {
  return _.every(ticket.inspections, (inspection) =>
    _.every(inspection.items, (item) => isInspectionItemComplete(item)),
  );
}

function canFinish(ticket) {
  const inspectionsComplete = _.every(ticket.inspections, (inspection) =>
    _.every(inspection.items, (item) => isInspectionItemComplete(item)),
  );
  const servicesComplete = _.every(ticket.services, (service) =>
    _.every(service.tasks, (task) => isServiceTaskComplete(task)),
  );
  return inspectionsComplete && servicesComplete;
}

function finish() {
  // TODO
  router.push("/");
}

async function applyTicketOperation(ticket, ticketOperation) {
  if (ticketOperation.type == "assign-vehicle") {
    const { vehicle } = ticketOperation.payload;
    ticket.vehicle = vehicle;
  } else if (ticketOperation.type === "set-vin-photo") {
    const { thumbnailData } = ticketOperation.payload;

    if (!ticket.vinPhoto) {
      ticket.vinPhoto = {};
    }
    ticket.vinPhoto.thumbnailData = thumbnailData;
  } else if (ticketOperation.type === "set-mileage-photo") {
    const { thumbnailData } = ticketOperation.payload;

    if (!ticket.mileagePhoto) {
      ticket.mileagePhoto = {};
    }
    ticket.mileagePhoto.thumbnailData = thumbnailData;
  } else if (ticketOperation.type === "set-engine-hours-photo") {
    const { thumbnailData } = ticketOperation.payload;

    if (!ticket.engineHoursPhoto) {
      ticket.engineHoursPhoto = {};
    }
    ticket.engineHoursPhoto.thumbnailData = thumbnailData;
  } else if (ticketOperation.type === "set-inspection-item-status") {
    const { inspectionId, inspectionItemId, status } = ticketOperation.payload;

    const inspection = ticket.inspections.find((i) => i.id == inspectionId);
    const item = inspection.items.find((i) => i.id == inspectionItemId);
    item.status = status;
  } else if (ticketOperation.type === "add-inspection-item-photo") {
    const { inspectionId, inspectionItemId, thumbnailData } =
      ticketOperation.payload;

    const inspection = ticket.inspections.find((i) => i.id == inspectionId);
    const item = inspection.items.find((i) => i.id == inspectionItemId);
    item.photos.push({ thumbnailData });
  } else if (ticketOperation.type === "remove-inspection-item-photo") {
    const { inspectionId, inspectionItemId, photoId } = ticketOperation.payload;

    const inspection = ticket.inspections.find((i) => i.id == inspectionId);
    const item = inspection.items.find((i) => i.id == inspectionItemId);
    item.photos = item.photos.filter((p) => p.id != photoId);
  } else if (ticketOperation.type === "set-inspection-item-comments") {
    const { inspectionId, inspectionItemId, comments } =
      ticketOperation.payload;

    const inspection = ticket.inspections.find((i) => i.id == inspectionId);
    const item = inspection.items.find((i) => i.id == inspectionItemId);
    item.comments = comments;
  } else if (ticketOperation.type === "add-service-task-photo") {
    const { serviceId, serviceTaskId, thumbnailData } = ticketOperation.payload;

    const service = ticket.services.find((i) => i.id == serviceId);
    const task = service.tasks.find((i) => i.id == serviceTaskId);
    task.photos.push({ thumbnailData });
  } else if (ticketOperation.type === "remove-service-task-photo") {
    const { serviceId, serviceTaskId, photoId } = ticketOperation.payload;

    const service = ticket.services.find((i) => i.id == serviceId);
    const task = service.tasks.find((i) => i.id == serviceTaskId);
    task.photos = task.photos.filter((p) => p.id != photoId);
  } else if (ticketOperation.type === "set-service-task-completion") {
    const { serviceId, serviceTaskId, completed } = ticketOperation.payload;

    const service = ticket.services.find((i) => i.id == serviceId);
    const task = service.tasks.find((i) => i.id == serviceTaskId);
    task.completed = completed;
  } else if (ticketOperation.type === "set-service-product-consumption") {
    const { serviceId, productId, quantity } = ticketOperation.payload;

    const service = ticket.services.find((i) => i.id == serviceId);
    const productConsumption = service.productConsumptions.find(
      (pc) => pc.productId == productId,
    );
    if (productConsumption) {
      productConsumption.quantity = quantity;
    } else {
      service.productConsumptions.push({ productId, quantity });
    }
  } else {
    console.warn("Did not apply ticket operation", ticketOperation);
  }
}

function readDataUrlAsBlob(dataUrl) {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
</script>

<template>
  <div class="grow flex flex-col overflow-y-scroll overscroll-none">
    <TopNav title="Ticket" backRoute="`/appointments/${appointment.id}" />
    
    <details open class="border border-muted">
      <summary class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10">
        <div class="grow flex justify-between items-center">
          <div class="pl-2 flex space-x-2 items-center">
            <!-- bootstrap-icons:truck -->
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-4" viewBox="0 0 16 16">
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
            </svg>
            <span>Vehicle</span>
          </div>

          <span>
            {{ ticket.vehicle.description }}
          </span>
        </div>
      </summary>

      <div class="flex flex-col divide-y divide-muted">
        <div class="p-4">
          <div v-if="ticket.vehicle?.id && !showingVehicleSelection" class="flex flex-col space-y-4">
            <div class="grid grid-cols-2">
              <span>VIN</span>
              <span>{{ ticket.vehicle.vin }}</span>

              <span>Description</span>
              <span>{{ ticket.vehicle.description }}</span>
            </div>

            <div class="flex justify-end">
              <button @click="() => showingVehicleSelection = true" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
                Select a different vehicle
              </button>
            </div>
          </div>

          <VehicleSelection v-if="showingVehicleSelection" :db="db" :customerId="customer.id" :on-select="selectVehicle" :on-dismiss="() => showingVehicleSelection = false" />
        </div>

        <template v-if="ticket.vehicle?.vin">
          <div @click="setFocus('vin-photo')" class="inspection-item p-4 shadow" :class="{ 'bg-base': !hasFocus('vin-photo'), 'bg-selected': hasFocus('vin-photo') }">
            <div class="flex justify-between">
              <div ref="vinPhotoElement" class="flex space-x-2 items-center font-semibold scroll-mt-10">
                <div v-if="ticket.vinPhoto">
                  <!-- bootstrap-icons:check-circle-fill -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-success" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                </div>
                <div v-else>
                  <!-- bootstrap-icons:circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-muted" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  </svg>
                </div>
                <h3>VIN</h3>
              </div>

              <div class="flex space-x-4 items-center" :class="{ 'hidden': hasFocus('vin-photo') }">
                <img v-if="ticket.vinPhoto?.thumbnailData" :src="ticket.vinPhoto.thumbnailData" class="size-8 object-cover" />
              </div>
            </div>

            <div class="pt-8 flex space-x-8" :class="{ 'hidden': !hasFocus('vin-photo') }">
              <img v-if="ticket.vinPhoto?.thumbnailData" :src="ticket.vinPhoto?.thumbnailData" @click="showPhoto(ticket.vinPhoto)" class="size-16 object-cover" />
              
              <div class="w-full flex space-x-4">
                <label className="relative w-full h-16 flex space-x-4 justify-center items-center border border-muted rounded-md fill-input-strong">
                  <span class="sr-only">VIN photo</span>
                  <!-- heroicons/outline:camera -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-muted">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>

                  <input id="vin_photo" type="file" capture="environment" accept="image/*" @change="setVinPhoto($event)" class="absolute top-0 right-0 bottom-0 left-0 opacity-0" />
                </label>
              </div>
            </div>
          </div>

          <div @click="setFocus('mileage-photo')" class="inspection-item p-4 shadow" :class="{ 'bg-base': !hasFocus('mileage-photo'), 'bg-selected': hasFocus('mileage-photo') }">
            <div class="flex justify-between">
              <div ref="mileagePhotoElement" class="flex space-x-2 items-center font-semibold scroll-mt-10">
                <div v-if="ticket.mileagePhoto">
                  <!-- bootstrap-icons:check-circle-fill -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-success" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                </div>
                <div v-else>
                  <!-- bootstrap-icons:circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-muted" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  </svg>
                </div>
                <h3>Mileage</h3>
              </div>

              <div class="flex space-x-4 items-center" :class="{ 'hidden': hasFocus('mileage-photo') }">
                <img v-if="ticket.mileagePhoto?.thumbnailData" :src="ticket.mileagePhoto.thumbnailData" class="size-8 object-cover" />
              </div>
            </div>  

            <div class="pt-8 flex space-x-8" :class="{ 'hidden': !hasFocus('mileage-photo') }">
              <img v-if="ticket.mileagePhoto?.thumbnailData" :src="ticket.mileagePhoto?.thumbnailData" @click="showPhoto(ticket.mileagePhoto)" class="size-16 object-cover" />
              
              <div class="w-full flex space-x-4">
                <label className="relative w-full h-16 flex space-x-4 justify-center items-center border border-muted rounded-md fill-input-strong">
                  <span class="sr-only">Mileage photo</span>
                  <!-- heroicons/outline:camera -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-muted">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>

                  <input id="mileage_photo" type="file" capture="environment" accept="image/*" @change="setMileagePhoto($event)" class="absolute top-0 right-0 bottom-0 left-0 opacity-0" />
                </label>
              </div>
            </div>
          </div>

          <div @click="setFocus('engine-hours-photo')" class="inspection-item p-4 shadow" :class="{ 'bg-base': !hasFocus('engine-hours-photo'), 'bg-selected': hasFocus('engine-hours-photo') }">
            <div class="flex justify-between">
              <div ref="engineHoursPhotoElement" class="flex space-x-2 items-center font-semibold scroll-mt-10">
                <div v-if="ticket.engineHoursPhoto">
                  <!-- bootstrap-icons:check-circle-fill -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-success" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
                </div>
                <div v-else>
                  <!-- bootstrap-icons:circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-muted" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  </svg>
                </div>
                <h3>Engine hours</h3>
              </div>

              <div class="flex space-x-4 items-center" :class="{ 'hidden': hasFocus('engine-hours-photo') }">
                <img v-if="ticket.engineHoursPhoto?.thumbnailData" :src="ticket.engineHoursPhoto.thumbnailData" class="size-8 object-cover" />
              </div>
            </div>  

            <div class="pt-8 flex space-x-8" :class="{ 'hidden': !hasFocus('engine-hours-photo') }">
              <img v-if="ticket.engineHoursPhoto?.thumbnailData" :src="ticket.engineHoursPhoto?.thumbnailData" @click="showPhoto(ticket.engineHoursPhoto)" class="size-16 object-cover" />
              
              <div class="w-full flex space-x-4">
                <label className="relative w-full h-16 flex space-x-4 justify-center items-center border border-muted rounded-md fill-input-strong">
                  <span class="sr-only">Engine hours photo</span>
                  <!-- heroicons/outline:camera -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-muted">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>

                  <input id="engine_hours_photo" type="file" capture="environment" accept="image/*" @change="setEngineHoursPhoto($event)" class="absolute top-0 right-0 bottom-0 left-0 opacity-0" />
                </label>
              </div>
            </div>
          </div>
        </template>
      </div>
    </details>

    <!-- Inspections -->
    <template v-for="inspection of ticket.inspections">
      <inspection :inspection="inspection" :hasFocus="hasFocus" :setFocus="setFocus" :setItemStatus="(item, status) => setInspectionItemStatus(inspection, item, status)" :addItemPhoto="(item, event) => addInspectionItemPhoto(inspection, item, event)" :removeItemPhoto="(item, photo) => removeInspectionItemPhoto(inspection, item, photo)" :setItemComments="(item, event) => setInspectionItemComments(inspection, item, event)" :showPhoto="showPhoto"></inspection>
    </template>

    <!-- Services -->
    <template v-for="service of ticket.services">
      <Service :service="service" :available="servicesAreAvailable(ticket)" :hasFocus="hasFocus" :setFocus="setFocus" :addTaskPhoto="(task, event) => addServiceTaskPhoto(service, task, event)" :removeTaskPhoto="(task, photo) => removeServiceTaskPhoto(service, task, photo)" :setTaskCompletion="(task, completed) => setServiceTaskCompletion(service, task, completed)" :onProductConsumptionSet="(product, quantity) => setServiceProductConsumption(service, product, quantity)" :removeProductConsumption="(productConsumption) => removeServiceProductConsumption(service, productConsumption)" :showPhoto="showPhoto" />
    </template>
    
    <div v-if="showingPhoto">
      <PhotoViewer :db="db" :photo="currentPhoto" :onDismiss="dismissPhoto" :onRemove="showingPhotoRemoval" />
    </div>

    <div class="p-2 flex justify-end">
      <template v-if="canFinish(ticket)">
        <button @click="finish" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
          Finish
        </button>
      </template>
    </div>
  </div>
</template>
