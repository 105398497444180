import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";
import { createPinia } from "pinia";

import Database from "./service_console/Database";
import Backend from "./service_console/Backend";
import { router } from "./service_console/router";
import PhotoProcessingWorkerClient from "../workers/PhotoProcessingWorkerClient";

import App from "./service_console/App.vue";

export default class extends Controller {
  static values = {
    organizationId: Number,
    organizationSlug: String,
    photoProcessingWorkerUrl: String,
  };

  async connect() {
    const organizationId = this.organizationIdValue;
    const organizationSlug = this.organizationSlugValue;

    const backend = new Backend(organizationId);

    const db = new Database(organizationSlug);
    await db.open();

    const photoProcessor = new PhotoProcessingWorkerClient(
      this.photoProcessingWorkerUrlValue,
    );

    // TODO Isolate organization data in separate IndexedDB databases.

    // Eagerly save all organization products to IndexedDB.
    const products = await backend.getProducts();
    await Promise.all(
      products.map(async (product) => await db.putProduct(product)),
    );

    // Eagerly save all organization customers to IndexedDB.
    const customers = await backend.getCustomers();
    await Promise.all(
      customers.map(async (customer) => await db.putCustomer(customer)),
    );

    // Eagerly save all organization vehicles to IndexedDB.
    const vehicles = await backend.getVehicles();
    await Promise.all(
      vehicles.map(async (vehicle) => await db.putVehicle(vehicle)),
    );

    // Refresh appointments and update tickets in IndexedDB.
    const appointments = await backend.getAppointments();
    await db.replaceAppointments(appointments);
    await Promise.all(
      appointments.map(async (appointment) => {
        await Promise.all(
          appointment.ticketIds.map(async (ticketId) => {
            const ticket = await backend.getTicket(ticketId);
            // console.log({ ticket });
            await db.putTicket(ticket);
          }),
        );
      }),
    );

    const pinia = createPinia();

    const app = createApp(App, { organizationId: this.organizationIdValue });

    app.use(pinia);
    app.use(router);

    app.provide("db", db);
    app.provide("photoProcessor", photoProcessor);

    app.mount(this.element);
  }
}
