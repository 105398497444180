<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import _ from "lodash";

const { db, photo, onDismiss, onRemove } = defineProps(["db", "photo", "onDismiss", "onRemove"]);

function onKeydown(event) {
  if (event.key === "Escape") {
    onDismiss();
  }
}

const fileRef = ref(null);

if (photo.fileKey) {
  db
    .getFile(photo.fileKey)
    .then((file) => (fileRef.value = file));
}

onMounted(() => {
  document.addEventListener("keydown", onKeydown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", onKeydown);
});

const hasUrl = !_.isNil(photo?.url);
</script>

<template>
  <div class="fixed inset-0 bg-backdrop transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center text-center items-center p-8">
      <div class="relative transform overflow-hidden rounded-lg shadow-xl bg-base transition-all">
        <img v-if="hasUrl" :src="photo.url" class="max-h-96 object-cover object-center" />
        <img v-else-if="fileRef" :src="fileRef.data" class=" max-h-96 object-contain" />
        <div class="flex justify-around">
          <button @click="onRemove()">Remove</button>
          <button @click="onDismiss()">Dismiss</button>
        </div>
      </div>
    </div>
  </div>
</template>
