<script setup>
import { nextTick, ref } from "vue";
import { isInspectionItemComplete } from "./util.js";
import InspectionItemStatus from "./InspectionItemStatus.vue";
import _ from "lodash";

const {
  inspection,
  hasFocus,
  setFocus,
  setItemStatus,
  addItemPhoto,
  removeItemPhoto,
  setItemComments,
  showPhoto,
} = defineProps([
  "inspection",
  "hasFocus",
  "setFocus",
  "setItemStatus",
  "addItemPhoto",
  "removeItemPhoto",
  "setItemComments",
  "showPhoto",
]);

const itemElements = ref({});

function hasFocusOnItem(item) {
  return hasFocus(`item-${item.id}`);
}

function focusOnItem(item) {
  setFocus(`item-${item.id}`);
  nextTick(() => {
    itemElements.value[item.id].scrollIntoView({ behavior: "smooth" });
  });
}

function registerItemElement(item, element) {
  // console.log("registering item element", { id: item.id });
  itemElements.value[item.id] = element;
}

function advanceFromItem(item) {
  const index = inspection.items.findIndex((i) => i.id == item.id);

  if (index < inspection.items.length - 1) {
    const nextItem = inspection.items[index + 1];
    focusOnItem(nextItem);
  }
}
</script>

<template>
  <details open class="border border-muted">
    <summary class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10">
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <!-- bootstrap-icons:clipboard -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-4" viewBox="0 0 16 16">
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
          </svg>

          <span>{{ inspection.description }}</span>
        </div>

        <span>
          {{ inspection.items.filter(isInspectionItemComplete).length }} / {{ inspection.items.length }}
        </span>
      </div>
    </summary>

    <div class="flex flex-col divide-y divide-muted">
      <!-- Inspection items -->
      <div v-for="item of inspection.items" @click="() => focusOnItem(item)" class="inspection-item p-4 shadow" :class="{ 'bg-neutral': !hasFocusOnItem(item), 'bg-raised': hasFocusOnItem(item) }">
        <div class="flex justify-between scroll-mt-24" :ref="(el) => registerItemElement(item, el)">
          <div class="flex space-x-2 items-center font-semibold">
            <div v-if="isInspectionItemComplete(item)">
              <!-- bootstrap-icons/check-circle-fill -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-success" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </div>
            <div v-else>
              <!-- bootstrap-icons/circle -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-muted" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              </svg>
            </div>
            <span>{{ item.description }}</span>
          </div>

          <div class="flex space-x-4 items-center" :class="{ 'hidden': hasFocusOnItem(item) }">
            <div v-if="item.comments && item.comments.length > 0">
              <!-- heroicons/outline:chat-bubble-bottom-center-text -->
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-muted">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
              </svg>
            </div>

            <div class="flex space-x-2">
              <div v-for="photo of item.photos">
                <img v-if="photo.thumbnailData" :src="photo.thumbnailData" class="size-8 object-cover" />
              </div>
            </div>

            <div :class="{ 'hidden': hasFocusOnItem(item) }">
              <template v-if="item.status">
                <InspectionItemStatus :status="item.status" />
              </template>
            </div>
          </div>
        </div>

        <div class="pt-8 flex flex-col space-y-4" :class="{ 'hidden': !hasFocusOnItem(item) }">
          <div class="flex flex-col space-y-1">
            <label>Status</label>

            <div class="w-full flex justify-around border rounded-md py-2">
              <label class="cursor-pointer">
                <span class="sr-only">Green</span>
                <input type="radio" value="green" :checked="item.status === 'green'" @change="setItemStatus(item, $event)" class="peer sr-only"></input>
                <div class="p-2 rounded-full text-muted peer-checked:bg-success-emphasis peer-checked:text-white">
                  <!-- heroicons/outline:check-circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </div>
              </label>

              <label class="cursor-pointer">
                <span class="sr-only">Yellow</span>
                <input type="radio" value="yellow" :checked="item.status === 'yellow'" @change="setItemStatus(item, $event)" class="peer sr-only"></input>
                <div class="p-2 rounded-full text-muted peer-checked:bg-attention-emphasis peer-checked:text-white">
                  <!-- heroicons/outline:exclamation-triangle -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                  </svg>
                </div>
              </label>

              <label class="cursor-pointer">
                <span class="sr-only">Red</span>
                <input type="radio" value="red" :checked="item.status === 'red'" @change="setItemStatus(item, $event)" class="peer sr-only"></input>
                <div class="p-2 rounded-full text-muted peer-checked:bg-danger-emphasis peer-checked:text-white">
                  <!-- heroicons/outline:x-circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </div>
              </label>

              <label class="cursor-pointer">
                <span class="sr-only">N/A</span>
                <input type="radio" value="n/a" :checked="item.status === 'n/a'" @change="setItemStatus(item, $event)" class="peer sr-only"></input>
                <div class="p-2 rounded-full text-muted peer-checked:bg-neutral-muted peer-checked:text-white">
                  <!-- heroicons/outline:minus-circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </div>
              </label>
            </div>
          </div>

          <div class="grow flex flex-col space-y-1">
            <label :for="`new_inspection_item_photo-${item.id}`">Photos</label>

            <div class="flex space-x-4">
              <div v-for="photo of item.photos" @click="showPhoto(photo, () => removeItemPhoto(item, photo))">
                <img v-if="photo.thumbnailData" :src="photo.thumbnailData" class="size-16 object-cover rounded-md" />
              </div>

              <div class="grow flex space-x-4">
                <div class="relative w-full h-16 flex space-x-4 justify-center items-center border border-muted rounded-md fill-input-strong">
                  <!-- heroicons/outline:camera -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-gray-200 dark:text-neutral-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>

                  <input type="file" :id="`new_inspection_item_photo-${item.id}`" capture="environment" accept="image/*" @change="addItemPhoto(item, $event)" class="absolute top-0 right-0 bottom-0 left-0 opacity-0" />
                </div>
              </div>
            </div>
          </div>

          <label class="flex flex-col space-y-1">
            <span>Comments</span>
            <input :value="item.comments" @change="setItemComments(item, $event)" placeholder="Comments" class="px-3 py-1.5 grow border border-muted rounded-md"></input>
          </label>

          <div class="flex justify-end">
            <button v-if="isInspectionItemComplete(item)" @click.stop="advanceFromItem(item)" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </details>
</template>
