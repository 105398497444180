import { IDBPDatabase, openDB } from "idb";
import { ulid } from "ulid";
import _ from "lodash";

class Database {
  private name: string;
  private version: number;
  private db: any;

  constructor(organizationSlug) {
    this.name = `${organizationSlug}-service-console`;
    this.version = 1;
  }

  public async open() {
    this.db = await openDB(this.name, this.version, {
      upgrade(db: IDBPDatabase) {
        db.createObjectStore("appointments", { keyPath: "id" });

        db.createObjectStore("tickets", { keyPath: "id" });

        db.createObjectStore("customers", { keyPath: "id" });

        db.createObjectStore("products", { keyPath: "id" });

        const vehiclesObjectStore = db.createObjectStore("vehicles", { keyPath: "id" });
        vehiclesObjectStore.createIndex("customerId", "customerId");

        db.createObjectStore("files", { keyPath: "ulid" });

        const ticketOperationsStore = db.createObjectStore("ticketOperations", { keyPath: ["ticketId", "key"] });
        ticketOperationsStore.createIndex("ulid", "ulid", { unique: true });

        console.log("[Database] IndexedDB database upgraded.")
      },
      blocked(currentVersion, blockedVersion, event) {
        console.log("blocked")
      },
      blocking(currentVersion, blockedVersion, event) {
        console.log("blocking")
      },
      terminated() {
        console.log("terminated")
      },
    });
  }

  public async clear() {
    await this.db.clear("appointments");
  }

  public async getAllAppointments() {
    return this.db.getAll("appointments");
  }

  public async replaceAppointments(appointments) {
    await this.db.clear("appointments");
    await Promise.all(
      appointments.map(async appointment => await this.db.put("appointments", appointment))
    )
  }

  public async getCustomer(id) {
    return await this.db.get("customers", id)
  }

  public async putCustomer(customer) {
    await this.db.put("customers", customer);
  }

  public async putProduct(product) {
    await this.db.put("products", product);
  }

  public async getProducts() {
    return await this.db.getAll("products");
  }

  public async getProduct(id) {
    return await this.db.get("products", id);
  }

  public async putVehicle(vehicle) {
    await this.db.put("vehicles", vehicle);
  }

  public async getVehiclesByCustomerId(customerId) {
    const index = this.db.transaction("vehicles").store.index("customerId");
    const vehicles = [];
    for await (const cursor of index.iterate(customerId)) {
      vehicles.push(cursor.value);
    }
    return vehicles;
  }

  public async getTicket(id) {
    return await this.db.get("tickets", id)
  }

  public async putTicket(ticket) {
    await this.db.put("tickets", ticket);
  }

  public async putTicketOperation(ticketOperation) {
    // console.log("[Database] putTicketOperation", { ticketOperation });
    await this.db.put("ticketOperations", ticketOperation);
  }

  public async getPendingTicketOperations() {
    const ticketOperations = await this.db.getAll("ticketOperations");
    return _.filter(ticketOperations, (ticketOperation) => {
      return (!ticketOperation.rejected);
    });
  }

  public async deleteTicketOperations(ulids) {
    for (const ulid of ulids) {
      const record = await this.db.getFromIndex("ticketOperations", "ulid", ulid);
      if (record) {
        await this.db.delete("ticketOperations", [record.ticketId, record.key]);
      }
    }
  }

  public async rejectTicketOperations(ulids) {
    await Promise.all(ulids.map(async ulid => {
      const record = await this.db.getFromIndex("ticketOperations", "ulid", ulid);
      await this.db.put("ticketOperations", { ...record, rejected: true })
    }))
  }

  public async addFile(file) {
    const newUlid = ulid();
    await this.db.add("files", { ...file, ulid: newUlid });
    return newUlid;
  }

  public async getFile(ulid) {
    return await this.db.get("files", ulid);
  }
}

export default Database;
