<script setup>
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.js";
import { router } from "./router.js";
import TopNav from "./TopNav.vue";

const props = defineProps(["id"]);

const store = useServiceConsoleStore();
const { getAppointment, getCustomer, getTicket } = storeToRefs(store);
const appointment = getAppointment.value(parseInt(props.id));

if (!appointment) router.push("/");

const customer = getCustomer.value(appointment.customerId);
const tickets = appointment.ticketIds.map((id) => getTicket.value(id));
</script>

<template>
  <div class="grow flex flex-col space-y-4">
    <TopNav title="Appointment" backRoute="/" />

    <div class="px-4 flex flex-col space-y-2">
      <div class="flex items-center space-x-2">
        <!-- heroicons/outline:calendar -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
          />
        </svg>

        <span>{{ appointment.formattedDate }}</span>
        <span>{{ appointment.time }}</span>
      </div>

      <div class="flex items-center space-x-2">
        <!-- heroicons/outline:building-office -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
          />
        </svg>

        <span>{{ customer.name }}</span>
      </div>

      <div class="flex items-center space-x-2">
        <!-- heroicons/outline:map -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
          />
        </svg>

        <span>{{ appointment.location?.address }}</span>
      </div>
    </div>

    <div class="px-4 flex flex-col space-y-2">
      <span class="font-semibold">Tickets</span>

      <div v-for="ticket of tickets" class="flex flex-col space-y-2">
        <router-link
          :to="`/appointments/${appointment.id}/tickets/${ticket.id}`"
          class="w-full px-2 py-2.5 flex flex-col border border-muted bg-raised shadow"
        >
          <div class="flex justify-between">
            <span>{{ ticket.description }}</span>
            <span>{{ ticket.vehicle?.description }}</span>
          </div>

          <div class="flex flex-col">
            <div
              v-for="inspection of ticket.inspections"
              class="flex items-center space-x-2"
            >
              <!-- heroicons/outline:clipboard -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                />
              </svg>

              <span class="text-sm">{{ inspection.description }}</span>
            </div>
            <div
              v-for="service of ticket.services"
              class="flex items-center space-x-2"
            >
              <!-- bootstrap-icons:tools -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="size-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"
                />
              </svg>

              <span class="text-sm">{{ service.description }}</span>

              <span
                v-if="service.serviceCategory"
                :class="`${service.serviceCategory.color}-category-badge`"
                >{{ service.serviceCategory.name }}</span
              >
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
