import { Controller } from "@hotwired/stimulus";
import { computePosition, shift } from "@floating-ui/dom";

export default class extends Controller {
  static values = {
    menuPlacement: { type: String, default: "bottom-end" },
  };
  static targets = ["menu"];

  connect() {}

  onWindowClick(event) {
    if (!this.element.contains(event.target)) {
      this.hide();
    }
  }

  show() {
    this.menuTarget.classList.remove("hidden");
    computePosition(this.element, this.menuTarget, {
      placement: this.menuPlacementValue,
      middleware: [shift()],
    }).then(({ x, y }) => {
      Object.assign(this.menuTarget.style, {
        left: `${x}px`,
        right: `${y}px`,
      });
    });
  }

  hide() {
    this.menuTarget.classList.add("hidden");
  }

  toggle() {
    if (this.menuTarget.classList.contains("hidden")) {
      this.show();
    } else {
      this.hide();
    }
  }
}
