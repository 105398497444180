<script setup>
import { inject, provide, ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store";
import SyncManager from "./SyncManager";
import TopNav from "./TopNav.vue";

const { organizationId } = defineProps(["organizationId"]);
const db = inject("db");

const loading = ref(true);

const store = useServiceConsoleStore();
const { message } = storeToRefs(store);

const syncManager = new SyncManager(organizationId, db, store);
provide("syncManager", syncManager);

// Track async activities that mutate state.
// Incremented when the activity starts. Decremented when the activity completes.
const activityCount = ref(0);
provide("activityCount", activityCount);

// Update database when store state changes
store.$subscribe((mutation, state) => {
  for (const ticketOperation of state.ticketOperations.values()) {
    db.putTicketOperation(JSON.parse(JSON.stringify(ticketOperation)));
  }
});

db.getProducts().then(async (products) => {
  await store.setProducts(products);
  store.rebuildProductsSearchIndex();
});

// Populate store with data from database
db.getAllAppointments().then(async (appointments) => {
  // console.log("appointments", appointments);

  await store.replaceAppointments(appointments);

  await Promise.all(
    appointments.map(async (appointment) => {
      const customer = await db.getCustomer(appointment.customerId);
      store.setCustomer(customer);

      await Promise.all(
        appointment.ticketIds.map(async (ticketId) => {
          const ticket = await db.getTicket(ticketId);
          store.setTicket(ticket);
        }),
      );
    }),
  );

  loading.value = false;
});

db.getPendingTicketOperations().then(async (ticketOperations) => {
  ticketOperations.forEach((ticketOperation) => {
    store.ticketOperations.set(ticketOperation.ulid, ticketOperation);
  });
});

async function reset() {
  await db.clear();
  store.$reset();
  // store.setMessage("Database truncated");
}
</script>

<template>
  <div v-if="loading" class="h-full grow flex flex-col space-y-4">
    <TopNav title="Service Console" />

    <div class="h-full w-full flex flex-col items-center justify-center space-y-4">
      <span aria-label="loading">Loading...</span>

      <svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
  <div v-else v-cloak class="h-full w-full flex flex-col relative">
    <router-view></router-view>

    <div class="w-full h-12 p-2 pb-4 flex justify-between items-center bg-base text-strong border-muted border-t z-30">
      <span v-if="message && message.length > 0">Message: {{ message }}</span>

      <div class="px-2 hidden">
        <button @click="reset" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
          Reset
        </button>
      </div>
    </div>
  </div>
</template>
