let _requestId = 0;
const newRequestId = () => _requestId++;

class PhotoProcessingWorkerClient {
  constructor(workerUrl) {
    // this.worker = new Worker(new URL(workerUrl));
    this.worker = new Worker(new URL("./photo_processing_worker", import.meta.url));
    this.requests = {};

    this.worker.onmessage = (event) => {
      const { requestId, status, result } = event.data;
      if (status == "success") {
        this.requests[requestId].call(this, result);
      } else {
        throw new Error("Status: `${status}");
      }
    }
  }

  resizeBlob({ width, height, quality, blob }) {
    const requestId = newRequestId();
    return new Promise((resolve, reject) => {
      this.requests[requestId] = (response) => resolve(response);
      this.worker.postMessage({
        requestId, operation: "resizeBlob", params: { width, height, quality, blob }
      });
      // TODO Reject promise after timeout
    });
  }
}

export default PhotoProcessingWorkerClient;
