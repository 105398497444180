<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store";

const { onSelect, onDismiss } = defineProps(["onSelect", "onDismiss"]);

const store = useServiceConsoleStore();
const { searchProducts } = storeToRefs(store);

const products = ref([]);

function filterProducts(query) {
  products.value = searchProducts.value(query);
}
</script>

<template>
  <div class="fixed inset-0 bg-backdrop transition-opacity" aria-hidden="true"></div>
  <div class="fixed inset-0 z-50 w-screen">
    <div class="flex min-h-full items-end justify-center text-center items-center p-8">
      <div class="relative w-96 h-96 transform overflow-hidden rounded-lg shadow-xl bg-raised transition-all flex flex-col">

        <div class="bg-emphasis flex px-3 py-1.5">
          <span class="text-md font-semibold text-on-emphasis">Add part</span>
        </div>

        <div class="h-full flex flex-col p-4 space-y-4">
          <label class="relative block w-full">
            <span class="sr-only">Search products</span>
            <!-- heroicons/outline:magnifying-glass -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-muted">
              <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            
            <input type="text" @input="(event) => filterProducts(event.target.value)" class="w-full px-3 py-1.5 pl-8 bg-base border rounded-md">
          </label>

          <div class="h-52 flex flex-col overflow-y-auto divide-y divide-muted">
            <div v-for="product in products" class="flex flex-col">
              <a @click="onSelect(product)" class="p-2 flex justify-between cursor-pointer hover:bg-selected">
                <div class="flex flex-col items-start">
                  <span>{{ product.name }}</span>
                  <span class="text-sm text-muted">{{ product.description }}</span>
                </div>

                <span>{{ product.number }}</span>
              </a>
            </div>
          </div>

          <div class="h-12 flex">
            <button @click="onDismiss">Cancel</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
