import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.automaticDismissal = setTimeout(() => {
      this.dismiss();
    }, 5000);

    this.onMouseOver = this.onMouseOver.bind(this);
    this.element.addEventListener("mouseover", this.onMouseOver);
  }

  dismiss() {
    this.element.remove();
  }

  onMouseOver() {
    if (this.automaticDismissal) {
      clearTimeout(this.automaticDismissal);
      this.automaticDismissal = null;
    }
  }
}
