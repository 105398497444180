import { Controller } from "@hotwired/stimulus";
import { Sortable } from "@shopify/draggable";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: ".item",
      handle: ".handle",
    });

    this.sortable.on("drag:stopped", (_event) => {
      this.reposition();
    });
  }

  reposition() {
    this.inputTargets.forEach((item, index) => {
      item.value = index + 1;
    });
  }
}
