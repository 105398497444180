import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  switch(event) {
    if (event.params.theme === "system") {
      delete localStorage.theme;
    } else {
      localStorage.theme = event.params.theme;
    }
    window.applyTheme();
  }
}
