<script setup>
import { ref } from "vue";
import Fuse from "fuse.js";

const { db, customerId, onSelect, onDismiss } = defineProps([
  "db",
  "customerId",
  "onSelect",
  "onDismiss",
]);

const ready = ref(false);
const fuse = ref(null);
const filteredVehicles = ref([]);

// OPTIMIZE Consider serializing the Fuse index and saving it in IndexedDB.

db.getVehiclesByCustomerId(customerId).then((vehicles) => {
  fuse.value = new Fuse(vehicles, { keys: ["vin", "description"] });
  ready.value = true;
});

function filterVehicles(query) {
  const results = fuse.value.search(query);
  filteredVehicles.value = results.map((i) => i.item);
}
</script>

<template>
  <div v-if="ready" class="relative flex flex-col space-y-2">
    <label class="flex space-x-2 items-center">
      <span class="font-semibold">Search vehicles</span>

      <input type="text" @input="(event) => filterVehicles(event.target.value)" class="grow px-3 py-1.5 border rounded-md">
    </label>

    <div v-if="filteredVehicles.length > 0" class="absolute top-12 left-0 right-0 bg-raised border shadow z-30">
      <div v-for="vehicle in filteredVehicles" class="flex flex-col divide-y divide-muted">
        <a @click="onSelect(vehicle)" class="p-2 flex justify-between cursor-pointer hover:bg-selected">
          <span>{{ vehicle.vin }}</span>
          <span>{{ vehicle.description }}</span>
        </a>
      </div>
    </div>

    <div class="flex justify-end">
      <button @click="onDismiss" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
        Cancel
      </button>
    </div>
  </div>
  <div v-else>
    <span>Not ready</span>
  </div>
</template>
