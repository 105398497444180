import { Controller } from "@hotwired/stimulus"
import { createApp, onMounted, ref } from "vue/dist/vue.esm-bundler.js";
import { deleteDB, openDB } from "idb";

export default class extends Controller {
  async connect() {
    createApp({
      setup() {
        const databases = ref([]);
        const currentDatabase = ref(null);
        const objectStores = ref([]);
        const currentObjectStore = ref(null);
        const objects = ref([]);

        onMounted(async () => {
          databases.value = await indexedDB.databases();
        });

        function selectDatabase(database) {
          objects.value = null;
          currentObjectStore.value = null;
          objectStores.value = null;

          currentDatabase.value = database;

          openDB(currentDatabase.value.name).then(db => {
            objectStores.value = domStringListToArray(db.objectStoreNames)
              .map(name => { return { name }; });
          });
        }

        function selectObjectStore(objectStore) {
          currentObjectStore.value = objectStore;

          openDB(currentDatabase.value.name).then(db => {
            db.getAll(currentObjectStore.value.name).then(o => objects.value = o);
          });
        }

        async function deleteAll() {
          await Promise.all(databases.value.map(database => {
            console.log("Deleting database:", database.name);
            deleteDB(database.name)
          }));

          objects.value = [];
          currentObjectStore.value = null;
          currentDatabase.value = null;
          databases.value = [];
        }

        return {
          databases,
          currentDatabase,
          objectStores,
          currentObjectStore,
          objects,

          selectDatabase,
          selectObjectStore,
          deleteAll
        }
      }
    }).mount(this.element);
  }
}

function domStringListToArray(list) {
  let result = [];
  for (let i = 0; i < list.length; i++) {
    result.push(list.item(i));
  }
  return result;
}
