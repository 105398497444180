import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "./HomeView.vue";
import AppointmentView from "./AppointmentView.vue";
import TicketView from "./TicketView.vue";

const routes = [
  { path: "/", component: HomeView },
  { path: "/appointments/:id", component: AppointmentView, props: true },
  { path: "/appointments/:appointmentId/tickets/:id", component: TicketView, props: true },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
