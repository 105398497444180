<script setup>
import { computed, nextTick, ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store";

import ProductSelection from "./ProductSelection.vue";

const {
  service,
  available,
  hasFocus,
  setFocus,
  addTaskPhoto,
  removeTaskPhoto,
  setTaskCompletion,
  onProductConsumptionSet,
  removeProductConsumption,
  showPhoto,
} = defineProps([
  "service",
  "available",
  "hasFocus",
  "setFocus",
  "addTaskPhoto",
  "removeTaskPhoto",
  "setTaskCompletion",
  "onProductConsumptionSet",
  "removeProductConsumption",
  "showPhoto",
]);

const store = useServiceConsoleStore();
const { getProduct } = storeToRefs(store);

const taskElements = ref({});

const showingProductSelection = ref(false);

function isTaskComplete(task) {
  if (task.completed) {
    return true;
  } else {
    return false;
  }
}

function hasFocusOnTask(task) {
  return hasFocus(`task-${task.id}`);
}

function focusOnTask(task) {
  setFocus(`task-${task.id}`);
  nextTick(() => {
    taskElements.value[task.id].scrollIntoView({ behavior: "smooth" });
  });
}

function registerTaskElement(task, element) {
  taskElements.value[task.id] = element;
}

function advanceFromTask(task) {
  const index = service.tasks.findIndex((i) => i.id == task.id);

  if (index < service.tasks.length - 1) {
    const nextTask = service.tasks[index + 1];
    focusOnTask(nextTask);
  }
}
</script>

<template>
  <div v-if="!available">
    <div class="h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none">
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <!-- bootstrap-icons:tools -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-4" viewBox="0 0 16 16">
            <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"/>
          </svg>
          
          <span>{{ service.description }}</span>
        </div>
      </div>
    </div>
  </div>

  <details v-else open class="border border-muted">
    <summary class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10">
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <!-- bootstrap-icons:tools -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-4" viewBox="0 0 16 16">
            <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"/>
          </svg>

          <span>{{ service.description }}</span>
        </div>

        <span>
          {{ service.tasks.filter(isTaskComplete).length }} / {{ service.tasks.length }}
        </span>
      </div>
    </summary>

    <div class="flex flex-col divide-y divide-muted">
      <!-- Service product consumptions -->
      <div class="flex flex-col space-y-2 p-4">
        <span class="font-semibold">Parts</span>

        <div v-for="productConsumption of service.productConsumptions.filter(pc => pc.quantity > 0)" class="flex justify-between">
          <div class="flex space-x-2">
            <span>{{ getProduct(productConsumption.productId)?.name }}</span>
            <span>Qty: {{ productConsumption.quantity }}</span>
          </div>

          <button type="button" @click="() => removeProductConsumption(productConsumption)" class="text-accent underline">Remove</button>
        </div>

        <div v-if="!showingProductSelection" class="flex justify-end">
          <button type="button" @click="showingProductSelection = true" class="text-accent">Add part</button>
        </div>

        <ProductSelection v-else :onSelect="(product) => { onProductConsumptionSet(product, 1); showingProductSelection = false; }" :onDismiss="() => showingProductSelection = false" />
      </div>

      <!-- Service tasks -->
      <div v-for="task of service.tasks" @click="() => focusOnTask(task)" class="service-task p-4 shadow" :class="{ 'bg-neutral': !hasFocusOnTask(task), 'bg-raised': hasFocusOnTask(task) }">
        <div class="flex justify-between">
          <div :ref="(el) => registerTaskElement(task, el)" class="flex space-x-2 items-center font-semibold scroll-mt-24">
            <div v-if="isTaskComplete(task)">
              <!-- bootstrap-icons:check-circle-fill -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-success" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </div>
            <div v-else>
              <!-- bootstrap-icons:check -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-5 text-muted" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              </svg>
            </div>
            <span>{{ task.description }}</span>
          </div>

          <div class="flex space-x-4 items-center" :class="{ 'hidden': hasFocusOnTask(task) }">
            <div class="flex space-x-2">
              <div v-for="photo of task.photos">
                <img v-if="photo.thumbnailData" :src="photo.thumbnailData" class="size-8 object-cover" />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-8 pb-4 flex flex-col space-y-8" :class="{ 'hidden': !hasFocusOnTask(task) }">
          <div class="grow flex flex-col space-y-1">
            <label :for="`new_service_task_photo-${task.id}`">Photos</label>

            <div class="flex space-x-4">
              <div v-for="photo of task.photos" @click="showPhoto(photo, () => removeTaskPhoto(task, photo))">
                <img v-if="photo.thumbnailData" :src="photo.thumbnailData" class="size-16 object-cover rounded-md" />
              </div>

              <div class="grow flex space-x-4">
                <div class="relative w-full h-16 flex space-x-4 justify-center items-center border border-muted rounded-md fill-input-strong">
                  <!-- heroicons/outline:camera -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-gray-200 dark:text-neutral-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                  </svg>

                  <input type="file" :id="`new_service_task_photo-${task.id}`" capture="environment" accept="image/*" @change="addTaskPhoto(task, $event)" class="absolute top-0 right-0 bottom-0 left-0 opacity-0" />
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex justify-around">
            <label class="flex items-center space-x-2 pr-4 rounded-full cursor-pointer text-muted has-[:checked]:bg-neutral-muted has-[:checked]:text-white group hover:bg-neutral-muted hover:text-emphasis">
              <input type="radio" :name="`service-task-${task.id}`" value="not-done" checked="!task.completed" @change="() => setTaskCompletion(task, false)" class="peer sr-only"></input>
              <div class="p-2 rounded-full text-muted peer-checked:bg-neutral-muted peer-checked:text-emphasis group-hover:bg-neutral-muted group-hover:text-emphasis">
                <template v-if="!task.completed">
                  <!-- bootstrap-icons:check2-circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-8" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                  </svg>
                </template>
                <template v-else>
                  <!-- bootstrap-icons/circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-8" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  </svg>
                </template>
              </div>
              <span class="text-muted peer-checked:text-emphasis group-hover:text-emphasis">Not done</span>
            </label>

            <label class="flex items-center space-x-2 pr-4 rounded-full cursor-pointer text-success-muted has-[:checked]:bg-success-emphasis has-[:checked]:text-white hover:bg-success-emphasis hover:text-white group">
              <input type="radio" :name="`service-task-${task.id}`" value="done" :checked="task.completed" @change="() => setTaskCompletion(task, true)" class="peer sr-only"></input>
              <div class="p-2 rounded-full text-muted peer-checked:bg-success-emphasis peer-checked:text-white group-hover:bg-success-emphasis group-hover:text-white">
                <template v-if="task.completed">
                  <!-- bootstrap-icons:check2-circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-8" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                  </svg>
                </template>
                <template v-else>
                  <!-- bootstrap-icons/circle -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="size-8" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  </svg>
                </template>
              </div>
              <span class="text-muted peer-checked:text-white group-hover:text-white">Done</span>
            </label>
          </div>

          <div class="flex justify-end">
            <button @click.stop="() => advanceFromTask(task)" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </details>
</template>
