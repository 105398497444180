import { defineStore } from "pinia";
import { ulid } from "ulid";
import Fuse from "fuse.js";
import _ from "lodash";

export const useServiceConsoleStore = defineStore("serviceConsole", {
  state: () => {
    return {
      appointments: [],
      customers: {},
      tickets: {},
      ticketOperations: new Map(),
      message: "",

      products: {},

      productsSearchIndex: null,
    };
  },
  getters: {
    getAppointment(state) {
      return (id) => state.appointments.find((a) => a.id === id);
    },

    getCustomer(state) {
      return (id) => state.customers[id];
    },

    getTicket(state) {
      return (id) => state.tickets[id];
    },

    hasPendingTicketOperation(state) {
      return (ticketId, type) =>
        _.some(
          state.ticketOperations.values(),
          (ticketOperation) =>
            ticketOperation.ticketId == ticketId &&
            ticketOperation.type === type,
        );
    },

    getPendingTicketOperations(state) {
      return (ticketId) =>
        [...state.ticketOperations].filter(([k, v]) => v.ticketId == ticketId);
    },

    getProduct(state) {
      return (id) => state.products[id];
    },

    searchProducts(state) {
      return (query) =>
        state.productsSearchIndex.search(query).map((i) => i.item);
    },
  },
  actions: {
    setProducts(products) {
      this.products = _.keyBy(products, "id");
    },

    rebuildProductsSearchIndex() {
      this.productsSearchIndex = new Fuse(_.values(this.products), {
        keys: ["name", "description", "number"],
      });
    },

    replaceAppointments(appointments) {
      this.appointments = appointments;
    },

    setCustomer(customer) {
      this.customers[customer.id] = customer;
    },

    setTicket(ticket) {
      this.tickets[ticket.id] = ticket;
    },

    addTicketOperation(ticketId, key, operation) {
      const newUlid = ulid();
      this.ticketOperations.set(newUlid, {
        ...operation,
        ticketId,
        key,
        ulid: newUlid,
      });
    },

    clearTicketOperations(ulids) {
      ulids.forEach((ulid) => this.ticketOperations.delete(ulid));
    },

    rejectTicketOperations(ulids) {
      this.ticketOperations.forEach((ticketOperation) => {
        if (_.includes(ulids, ticketOperation.ulid)) {
          ticketOperation.rejected = true;
        }
      });
    },

    setTicketVinPhotoThumbnail(ticketId, data) {
      if (!this.tickets[ticket.id].vinPhoto) {
        this.tickets[ticket.id].vinPhoto = {};
      }
      this.ticket[ticket.id].vinPhoto.thumbnailData = data;
    },

    setMessage(message) {
      this.message = message;
    },
  },
});
