import _ from "lodash";

export function isInspectionItemComplete(item) {
  if (item.status === "green") {
    return item.photos.length > 0;
  } else if (_.includes(["yellow", "red"], item.status)) {
    return item.photos.length > 0 && item.comments?.length > 1;
  } else if (item.status === "n/a") {
    return true;
  }
  return false;
}

export function isServiceTaskComplete(task) {
  if (task.completed) {
    return true;
  } else {
    return false;
  }
}
